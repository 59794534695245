/* eslint-disable indent  */
/* eslint-disable camelcase  */
/* no-param-reassign */

import React, { useEffect, useState, useReducer, useContext } from 'react';
import Loading from 'shared/loading';
import { firebaseContext } from 'context/firebase-context';
import Main from './main';
import Countdown from './count-down';

function videoReducer(state, action) {
  switch (action.type) {
    case 'with_callback':
      return action.cb(state);
    case 'update':
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

const initialState = {
  times: [],
  messages: null,
  allMessages: null,
  videoId: null,
  permissions: false,
  playing: null,
  ended: null,
  upcoming: null,
  loading: true,
  mute: true
};

export default function WebinarComponent(props) {
  // STATE
  const [state, dispatch] = useReducer(videoReducer, initialState);
  const { duration, upcoming: isUpcoming } = state;
  const { db } = useContext(firebaseContext);
  const [userMessage, setUserMessage] = useState('');

  // PROPS
  const { webinar_id, webinar_time, id } = props.user;

  // METHODS
  const handleVideoState = async stream => {
    const videoDuration =
      typeof stream !== 'undefined' ? stream.current.duration * 1000 : duration;
    const currentTime = Date.now();
    const upcoming = currentTime < webinar_time;
    const ended = currentTime > webinar_time + videoDuration;
    const playing =
      currentTime < webinar_time + videoDuration && currentTime > webinar_time;
    const videoTime = (currentTime - webinar_time) / 1000;

    if (playing && stream) {
      stream.current.currentTime = videoTime;
    }
    const messageTimes = Object.keys(state.allMessages);
    const pastMessages =
      videoTime >= Number(messageTimes[0]) &&
      messageTimes.reduce((acc, curr) => {
        return Number(curr) <= videoTime
          ? [...acc, ...state.allMessages[curr]]
          : acc;
      }, []);

    const newState = {
      upcoming,
      playing,
      ended,
      duration: videoDuration,
      loading: false
    };

    const handlePlaying = () => {
      dispatch({
        type: 'update',
        payload: newState
      });
      stream.current.removeEventListener('playing', handlePlaying);
    };

    if (pastMessages && playing) {
      await dispatch({
        type: 'update',
        payload: {
          messages: pastMessages,
          playing
        }
      });
      stream.current.onplaying = handlePlaying;
    } else {
      await dispatch({
        type: 'update',
        payload: {
          ...newState,
          messages: []
        }
      });
    }
    if (typeof stream !== 'undefined') {
      db.ref(`webinars/${webinar_id}/duration`).set(stream.current.duration);
    }
  };

  const initMessages = messages => {
    const reducedMessages = messages.reduce((acc, curr) => {
      const accumulator = acc[curr.seconds]
        ? {
            ...acc,
            [curr.seconds]: [...acc[curr.seconds], curr].map((msg, i) => ({
              ...msg,
              delay: i * 100
            }))
          }
        : {
            ...acc,
            [curr.seconds]: [{ ...curr, delay: 0 }]
          };
      return accumulator;
    }, {});
    dispatch({
      type: 'with_callback',
      cb: currentState => {
        return {
          ...currentState,
          allMessages: reducedMessages
        };
      }
    });
  };

  const initApp = () => {
    const permissionsDb = db.ref(`webinar_users/${id}/permissions`);
    const liveMessagesDb = db.ref(
      `webinars/${webinar_id}/webinar_times/${webinar_time}/live_messages`
    );
    const videoIdDb = db.ref(`webinars/${webinar_id}/video_id`);
    const autoMessageDb = db.ref(`webinars/${webinar_id}/auto_messages`);
    const webinarUserDb = db.ref(
      `webinars/${webinar_id}/webinar_times/${webinar_time}/users/${id}`
    );
    webinarUserDb.once('value').then(snap => {
      webinarUserDb.set({ ...snap.val(), joined: true });
    });
    permissionsDb.on('value', currentUser => {
      dispatch({
        type: 'update',
        payload: {
          permissions: currentUser.val()
        }
      });
    });
    liveMessagesDb.on('child_added', message => {
      dispatch({
        type: 'with_callback',
        cb: currentState => {
          const { messages, permissions } = currentState;
          const isUserMessage = message.val().id === id;
          if (currentState.videoId && permissions) {
            return {
              ...currentState,
              messages: [...messages, { ...message.val() }]
            };
          }
          return currentState.videoId && isUserMessage
            ? {
                ...currentState,
                messages: [...messages, { ...message.val() }]
              }
            : currentState;
        }
      });
      setUserMessage('');
    });
    videoIdDb.on('value', videoId => {
      dispatch({
        type: 'update',
        payload: {
          videoId: videoId.val()
        }
      });
      autoMessageDb.on('value', messages => {
        initMessages(messages.val());
      });
    });
    return () => {
      permissionsDb.off();
      liveMessagesDb.off();
      videoIdDb.off();
      autoMessageDb.off();
    };
  };

  // EFFECTS
  useEffect(() => {
    if (isUpcoming) {
      window.isUpcoming = setInterval(() => {
        handleVideoState();
      }, 1000);
    }
    if (!isUpcoming && window.isUpcoming) {
      clearInterval(window.isUpcoming);
    }
  }, [isUpcoming]);

  useEffect(() => {
    return db ? initApp() : () => null;
  }, [db]);

  // RENDER
  if (state.loading || state.playing) {
    return (
      <React.Fragment>
        {state.loading && <Loading keepLock />}
        <Main
          state={state}
          dispatch={dispatch}
          db={db}
          handleVideoState={handleVideoState}
          userMessage={userMessage}
          setUserMessage={setUserMessage}
          {...props}
        />
      </React.Fragment>
    );
  }
  if (state.upcoming) return <Countdown date={webinar_time} />;
  if (state.ended) return <div>ENDED</div>;
}
