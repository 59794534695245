/* eslint-disable indent  */
/* eslint-disable camelcase  */

import React, { useEffect, useState, useRef } from 'react';

import s from './chat-bubble.module.scss';

export default function ListItem(props) {
  // PROPS
  const {
    children,
    delay: messageDelay,
    user,
    name,
    scrollReady,
    setScrollReady,
    wrapper,
    isLoading
  } = props;
  const delay = messageDelay || 0;

  // STATE
  const [dimensions, setDimensions] = useState({
    minWidth: props.chatWrapper.current.getBoundingClientRect().width - 20
  });

  // REFS
  const message = useRef();
  const messageWrapper = useRef();
  const timeout = useRef();
  const isMobile = 'ontouchstart' in window;

  // CONSTANTS
  const transition = `opacity 200ms ease-in ${delay * 2 +
    50}ms, height 200ms ease-in ${delay * 2}ms`;
  const style = dimensions.height
    ? {
        transition,
        opacity: 1,
        height: dimensions.height,
        minWidth: dimensions.minWidth
      }
    : {
        transition,
        opacity: 0,
        minWidth: dimensions.minWidth,
        height: 0
      };

  // METHODS
  const handleDimensions = () => {
    const { height: elementHeight } = message.current.getBoundingClientRect();
    const minWidth =
      props.chatWrapper.current.getBoundingClientRect().width - 20;
    setDimensions({ height: elementHeight, minWidth });
  };

  const handleScrollReady = () => {
    if (!isMobile) return false;
    const { scrollHeight, scrollTop } = wrapper.current;
    const { height } = wrapper.current.getBoundingClientRect();
    const isLarger =
      scrollHeight >= props.chatWrapper.current.getBoundingClientRect().height;
    const isReady = scrollHeight - scrollTop === Math.floor(height);
    if (!isLarger) return null;
    return isLarger && isReady;
  };

  const handleScroll = () => {
    const smooth = isLoading ? {} : { behavior: 'smooth' };
    timeout.current = setTimeout(() => {
      wrapper.current.scrollTo({
        top: wrapper.current.scrollHeight,
        ...smooth
      });
    }, delay * 2 + 200);
  };

  // EFFECTS
  useEffect(() => {
    handleDimensions();
    const wrapperBottom =
      Math.ceil(messageWrapper.current.getBoundingClientRect().bottom) + 10;
    const parentBottom = wrapper.current.getBoundingClientRect().bottom;
    if (isMobile && wrapperBottom === parentBottom) {
      setScrollReady(true);
    }
    if (isMobile) {
      const readyCheck = handleScrollReady();
      if (scrollReady || readyCheck) {
        handleScroll();
      }
    }
    if (!isMobile) {
      if (scrollReady) {
        handleScroll();
      }
    }
    if (!!timeout.current && !scrollReady) {
      clearTimeout(timeout.current);
    }
  }, [scrollReady]);

  useEffect(() => {
    window.addEventListener('resize', handleDimensions);
    return () => window.removeEventListener('resize', handleDimensions);
  }, []);

  // RENDER
  return (
    <div
      ref={messageWrapper}
      style={style}
      className={`${s.itemWrapper} ${user ? s.user : ''}`}
    >
      <p className={s.item} ref={message}>
        <span className={`${s.bubble} ${user ? s.user : s.attendee}`}>
          <span className={s.name}>{name}</span>
          <br />
          <span
            dangerouslySetInnerHTML={{
              __html: children.replace(/(?:\r\n|\r|\n)/g, '<br>')
            }}
          />
        </span>
      </p>
    </div>
  );
}
