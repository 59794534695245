/* eslint-disable import/no-extraneous-dependencies  */
/* eslint-disable quotes  */

import React, { useEffect, useState, useContext } from 'react';
import { firebaseContext } from 'context/firebase-context';
import { Helmet } from 'react-helmet';
import fetchWebinar from 'api/webinar';
import queryString from 'query-string';
import Loading from 'shared/loading';
import 'emoji-mart/css/emoji-mart.css';

import ChatRoom from './chat-room';

export default function Webinar({ href }) {
  const [data, setData] = useState({});
  const { auth, admin } = useContext(firebaseContext) || {};

  const init = async () => {
    try {
      const { query } = queryString.parseUrl(href);
      const user = query.id && (await fetchWebinar(`/${query.id}`));
      if (user) {
        return setData({ success: true, user: { ...user, id: query.id } });
      }
      if (!user && !admin.displayName) {
        return setData({ error: true, url: '/404' });
      }
      return setData({ error: true, url: '/webinar/admin' });
    } catch (e) {
      setData({ error: true, url: '/404' });
    }
  };

  useEffect(() => {
    if (auth && admin) {
      init();
    }
  }, [auth, admin]);

  return (
    <>
      <Helmet>
        <style type="text/css">
          {`html,body,#___gatsby,div[role='group'] {height: 100%}`}
        </style>
      </Helmet>
      {data.success ? <ChatRoom {...data} /> : <Loading {...data} />}
    </>
  );
}
