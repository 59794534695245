import React from 'react';
import s from './countdown.module.scss';

export default function Countdown({ date }) {
  const myDate = Date.now();
  const timeLeft = date - myDate + 1000;
  const timeInHours = timeLeft / 1000 / 60 / 60;
  const timeInMinutes = timeInHours * 60;
  const hours = Math.floor(timeInHours);
  const minutes = Math.floor(timeInMinutes);
  const seconds = Math.floor((timeInMinutes - minutes) * 60);
  const displayHours = `${hours < 10 ? 0 : ''}${hours}`;
  const displayMinutes = `${minutes < 10 ? 0 : ''}${minutes}`;
  const displaySeconds = `${seconds < 10 ? 0 : ''}${seconds}`;
  return (
    <div className={s.root}>
      <div className={s.wrapper}>
        <span>{displayHours[0]}</span>
        <span>{displayHours[1]}</span>
        <span className={s.colon}>:</span>
        <span>{displayMinutes[0]}</span>
        <span>{displayMinutes[1]}</span>
        <span className={s.colon}>:</span>
        <span>{displaySeconds[0]}</span>
        <span>{displaySeconds[1]}</span>
      </div>
    </div>
  );
}
