/* eslint-disable indent  */
/* eslint-disable camelcase  */

import React, { useEffect, useState, useRef } from 'react';
import Button from 'button';
import { Helmet } from 'react-helmet';
import enableInline from 'iphone-inline-video';
import { Picker } from 'emoji-mart';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import anchorme from 'anchorme';
import Icon from 'icon';
import ChatBubble from './chat-bubble';

import s from './main.module.scss';

export default function Main({
  user,
  state,
  dispatch,
  db,
  handleVideoState,
  userMessage,
  setUserMessage
}) {
  // PROPS
  const { webinar_id, webinar_time, id } = user;

  // STATE
  const [emoji, setEmoji] = useState(false);
  const [scrollReady, setScrollReady] = useState(true);
  const { mute } = state;

  // CONSTANTS
  const videoScript =
    state.videoId &&
    `<stream src="${state.videoId}" playsInline muted></stream>`;

  const streamSrc =
    state.videoId &&
    `https://embed.videodelivery.net/embed/r4xu.fla9.latest.js?video=${
      state.videoId
    }`;

  const isMobile = 'ontouchstart' in window;

  // REFS
  const main = useRef();
  const chatWrapper = useRef();
  const messageWrapper = useRef();
  const textarea = useRef();
  const stream = useRef();

  // METHODS
  const scrollStop = (callback, el) => {
    if (!callback || typeof callback !== 'function') return;
    el.addEventListener(
      'scroll',
      () => {
        window.clearTimeout(window.isScrolling);
        window.isScrolling = setTimeout(() => {
          callback();
        }, 66);
      },
      false
    );
  };

  const handleAutoMessage = () => {
    dispatch({
      type: 'with_callback',
      cb: currentState => {
        if (!currentState.playing) return currentState;
        const { times, messages, allMessages } = currentState;
        const currentTime = Math.floor(stream.current.currentTime);
        if (!times.includes(currentTime)) {
          const newMessages = allMessages[currentTime];
          return newMessages
            ? {
                ...currentState,
                messages: [...messages, ...newMessages],
                times: [...times, currentTime]
              }
            : currentState;
        }
        return currentState;
      }
    });
  };

  const handleOnLoadData = () => handleVideoState(stream);

  const initVideo = async () => {
    try {
      stream.current.ontimeupdate = handleAutoMessage;
      stream.current.onloadeddata = handleOnLoadData;
      await enableInline(stream);
      await stream.current.play();
      window.videoObserver.disconnect();
    } catch (e) {
      console.log(e, 'ERROR');
    }
  };

  const handleWake = lastTime => {
    const TIMEOUT = 6000;
    const time = lastTime || new Date().getTime();

    setTimeout(() => {
      const currentTime = new Date().getTime();
      if (currentTime > time + TIMEOUT + 2000) {
        handleVideoState(stream);
      }
      handleWake(currentTime);
    }, TIMEOUT);
  };

  const handleSubmit = async e => {
    if (e) e.preventDefault();
    const currentUserMsg = {
      ...user,
      text: anchorme(userMessage),
      id,
      seconds: Math.round(stream.current.currentTime)
    };
    db.ref(
      `webinars/${webinar_id}/webinar_times/${webinar_time}/live_messages`
    ).push(currentUserMsg);
  };

  const handleUserMessage = e => {
    const { value } = e.target;
    setUserMessage(value);
  };

  const handleEnterKey = e => {
    if (e.keyCode === 13 && e.target.value && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const triggerEmoji = () => setEmoji(previousEmoji => !previousEmoji);

  const handleEmoji = e => {
    setUserMessage(currentMessage => `${currentMessage}${e.native}`);
    triggerEmoji();
    textarea.current.focus();
  };

  const checkScrollPos = () => {
    const { scrollHeight, scrollTop } = messageWrapper.current;
    const { height } = messageWrapper.current.getBoundingClientRect();
    const isLarger =
      scrollHeight >= chatWrapper.current.getBoundingClientRect().height;
    const isReady = scrollHeight - scrollTop === Math.floor(height);
    return { isLarger, isReady };
  };

  const handleScrollReady = e => {
    setScrollReady(prevScroll => {
      const { isLarger, isReady } = checkScrollPos();
      const deltaY = e.touches ? true : e.deltaY > 0;
      if (!isLarger) return prevScroll;
      if (deltaY && prevScroll && !isMobile) return prevScroll;
      return isLarger && isReady && deltaY;
    });
  };

  const scrollDown = () => {
    const { scrollHeight } = messageWrapper.current;
    messageWrapper.current.scrollTo({
      top: scrollHeight
    });
    return setScrollReady(true);
  };

  const handleMute = () => {
    dispatch({
      type: 'with_callback',
      cb: currentState => {
        stream.current.muted = !currentState.mute;
        return {
          ...currentState,
          mute: !currentState.mute
        };
      }
    });
  };

  // EFFECTS
  useEffect(() => {
    if (state.allMessages) {
      const streamElement = main.current.querySelector('stream');
      const config = { attributes: true, childList: true, subtree: true };
      const callback = mutationsList => {
        mutationsList.some(mutation => {
          if (
            mutation.type === 'attributes' &&
            mutation.target.tagName === 'VIDEO' &&
            mutation.target.getAttribute('muted') &&
            mutation.target.getAttribute('playsinline')
          ) {
            stream.current = mutation.target;
            initVideo();
            return true;
          }
          return false;
        });
      };
      const observer = new MutationObserver(callback);
      observer.observe(streamElement, config);
      window.videoObserver = observer;
    }
  }, [state.allMessages]);

  useEffect(() => {
    disableBodyScroll(messageWrapper.current);
    handleWake();
    if ('ontouchstart' in window) {
      messageWrapper.current.addEventListener('touchstart', handleScrollReady);
      messageWrapper.current.addEventListener('touchmove', handleScrollReady);
      messageWrapper.current.addEventListener('touchend', handleScrollReady);
    } else {
      messageWrapper.current.addEventListener('wheel', (e) => {
        if (!window.throttle) {
          handleScrollReady(e);
          window.throttle = true;
          setTimeout(() => {
            window.throttle = false;
          }, 100);
        }
      });
    }

    scrollStop(() => {
      const { isLarger, isReady } = checkScrollPos();
      if (isLarger && isReady) {
        setScrollReady(true);
      }
    }, messageWrapper.current);
    return () => clearAllBodyScrollLocks();
  }, []);

  // RENDER
  return (
    <main className={s.root} ref={main}>
      <div className={s.header}>
        <p>ORGASMIC MANIFESTING SYSTEM</p>
        <Button noArrow onClick={handleMute} className={s.muteButton}>
          {mute ? 'UNMUTE' : 'MUTE'}
        </Button>
      </div>
      <div className={s.wrapper}>
        {/* VIDEO START */}
        {state.allMessages && videoScript && (
          <React.Fragment>
            <Helmet>
              <script
                data-cfasync="false"
                defer
                type="text/javascript"
                src={streamSrc}
              />
            </Helmet>
            <div
              className={`${s.video} ${state.playing ? s.active : s.active}`}
            >
              <div
                className={s.videoWrapper}
                dangerouslySetInnerHTML={{ __html: videoScript }}
              />
            </div>
          </React.Fragment>
        )}
        {/* VIDEO END */}
        <div className={s.chatWrapper} ref={chatWrapper}>
          <div className={s.chatBox}>
            <div className={s.messageWrapper} ref={messageWrapper}>
              {state.messages &&
                state.messages.map((message, i) => (
                  <ChatBubble
                    key={`${message.text}-${i}`}
                    user={message.id === id}
                    isLoading={state.loading}
                    wrapper={messageWrapper}
                    chatWrapper={chatWrapper}
                    scrollReady={scrollReady}
                    setScrollReady={setScrollReady}
                    {...message}
                  >
                    {message.text}
                  </ChatBubble>
                ))}
            </div>
          </div>
          <button
            onClick={scrollDown}
            className={`${s.arrow} ${scrollReady ? '' : s.active}`}
            type="button"
          >
            <Icon icon="readmorearrow" />
          </button>
          <form className={s.textArea} onSubmit={handleSubmit}>
            <button className={s.icon} type="button" onClick={triggerEmoji}>
              <Icon icon="emoji" />
            </button>
            <Picker
              onSelect={handleEmoji}
              showPreview={false}
              style={{
                position: 'absolute',
                bottom: 34,
                left: 10,
                width: 'calc(100% - 20px)',
                transform: emoji ? 'scale(1)' : 'scale(0)',
                transition: 'transform 200ms ease-in-out',
                transformOrigin: 'bottom right'
              }}
            />
            <textarea
              name="message"
              id="message"
              value={userMessage}
              onChange={handleUserMessage}
              onKeyDown={handleEnterKey}
              ref={textarea}
            />
          </form>
        </div>
      </div>
    </main>
  );
}
